import React, { Suspense, useMemo, lazy, useRef } from "react";
import HttpIcon from '@mui/icons-material/Http';
import useTheme from '@mui/material/styles/useTheme';
import { alpha } from "@mui/material";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';

import SvgIcon from '@mui/material/SvgIcon';
import { Link } from "gatsby";
import { motion } from "framer-motion";
import jpgVodaVlese11dusty from "../../../../static/sf/im/bg/voda-v-lese-w1200h900-photoIllustration11-dusty.jpg"
import BowListLazy from "../../_shared/shaping/BowListLazy"
import AnimateScrollIntoViewport from "../../_shared/AnimateScrollIntoViewport";
import SeparatorAbsSvg2 from "../../_shared/shaping/SeparatorAbsSvg2";
import imgBgPipe from "../../../images/bodkyOk/verticalAnim/bodky1-animQ-vert-w97-h240.png"
import ExcelImage from "../../_shared/excelImage"
import { useBreakpoint } from "../../_shared/contextBreakpointMediaQ";
import imgLeaf1 from "../../../images/leaves/realLeaf1.png"
import DropEffect from "../../_shared/dropEffect/DropEffect";
import { optionsDropEffect } from "../../_shared/dropEffect/DropEffect";

import imgGearWheelA from "../../../images/svvgNormally/gearWheelA-w254.svg"
import FrameMaskForDiv from "../../_shared/FrameMaskForDiv";
import { POLYGON_RECTANGLE_RIGHT_NULL, POLYGON_RECTANGLE, POLYGON_RECTANGLE_10_POINTS_RIGHT, POLYGON_RECTANGLE_10_POINTS_LEFT, POLYGON_STAR } from "../../../utils/clipPathsConstants";
import AtroposEffectTreeLazy from "../../_shared/AtroposEffectTreeLazy";
const LazySvgInParentPaddingsWithSettings = lazy(() => import(/* webpackMode: "lazy", webpackPreload: true, webpackChunkName: 'SvgInParentPaddingsWithSettingsLazy' */ "../../_shared/svgInParentPaddings/SvgInParentPaddingsWithSettings"));
const LazyDivsWithSvgInParentPaddingsViaMuiImageList = lazy(() => import(/* webpackMode: "lazy", webpackPreload: true, webpackChunkName: 'DivsWithSvgInParentPaddingsViaMuiImageListLazy' */ "../../_shared/DivsWithSvgInParentPaddingsViaMuiImageList"));
const LazySvgInParentPaddingsAnimated = lazy(() => import(/* webpackMode: "lazy", webpackPreload: true, webpackChunkName: 'SvgInParentPaddingsAnimatedLazy' */ "../../_shared/svgInParentPaddings/SvgInParentPaddingsAnimated.js"));
const LazyDivWithCircleAndCirclesAroundClipPathAnimation = lazy(() => import(/* webpackMode: "lazy", webpackPreload: true, webpackChunkName: 'DivWithCircleAndCirclesAroundClipPathAnimationLazy' */ "../../_shared/DivWithCircleAndCirclesAroundClipPathAnimation"));
const LazyDivsWithBgGradientAnimatedViaMuiImageList = lazy(() => import(/* webpackMode: "lazy", webpackPreload: true, webpackChunkName: 'DivsWithBgGradientAnimatedViaMuiImageListLazy' */ "../../_shared/DivsWithBgGradientAnimatedViaMuiImageList"));

const LazyPaintSvgWithMouse = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'PaintSvgWithMouseLazy' */ "../../_shared/animatedSvgImages/PaintSvgWithMouse"));

const LazyAbsImagesAnimation = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'AbsImagesAnimationLazy' */ "./AbsImagesAnimation"));
const LazyCometWithForm = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'CometWithFormLazy' */ "../../_shared/CometRotatingInsideElementOnPerimeterAnimation/CometWithForm"));
const LazyAnimatingColumnsOrderingWithFlip = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'AnimatingColumnsOrderingWithFlipLazy' */ "../../_shared/AnimatingColumnsOrderingWithFlip"));
const LazyCometWithFormMuiDrawer = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'CometWithFormMuiDrawerLazy' */ "../../_shared/CometRotatingInsideElementOnPerimeterAnimation/CometWithFormMuiDrawer.tsx"));
const LazyAnimateSvg3dBallWithMuiFormOnDrawer = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'AnimateSvg3dBallWithMuiFormOnDrawerLazy' */ "../../_shared/AnimateSvg3dBallWithMuiFormOnDrawer"));
const LazyCircularMenuNature = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'CircularMenuNatureLazy' */ `./CircularMenuNature`));
//, { cacheKey: "CircularMenuNature" } // , ssr: false
const LazyMaskingChildren = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'MaskingChildrenLazy' */ "../../_shared/MaskingChildren"));
const LazySeparatorMovingImgOnPipe = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'SeparatorMovingImgOnPipeLazy' */ "../../_shared/SeparatorMovingImgOnPipe/SeparatorMovingImgOnPipe"));


const LazyUprightAvatarsMoving = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'UprightAvatarsMovingLazy' */ "../../_shared/UprightAvatarsMoving"));
const LazyUpright3dSvgsMoving = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'Upright3dSvgsMovingLazy' */ "../../_shared/Upright3dSvgsMoving"));
const LazyWavePathBox = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'WavePathBoxLazy' */ "../../_shared/WavePathBox"));
const LazyCircleList = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'CircleListLazy' */ "../../_shared/CircleList"));
const LazyAnimateItemsFromCentreToAroundCircleAndTimeoutBack = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'AnimateItemsFromCentreToAroundCircleAndTimeoutBackLazy' */ "../../_shared/AnimateItemsFromCentreToAroundCircleAndTimeoutBack"));

const LazyParallaxMotion = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'ParallaxMotionLazy' */ "./ParallaxMotion"));

const LazyAtroposEffectHillyLandWithTrees = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'AtroposEffectHillyLandWithTreesLazy' */ "../../_shared/AtroposEffectHillyLandWithTrees"));

const LazyLeavesAnimation = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'LeavesAnimationLazy' */ "../../_shared/leavesAnimation/LeavesAnimation"))
//, { ssr: false }

const LazyNatureCardsAroundEllipseMotion = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'NatureCardsAroundEllipseMotionLazy' */ `./NatureCardsAroundEllipseMotion`));
//      {
//     fallback: <div>Loading NatureCardsAroundEllipseMotion...</div>,
// }

const LazyHotelCardsAroundEllipseMotionWithData = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'HotelCardsAroundEllipseMotionWithDataLazy' */ `./HotelCardsAroundEllipseMotionWithData`));
const LazyCirclesMotionX4PopularWebsitesLR = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'CirclesMotionX4PopularWebsitesLRLazy' */ `../../_shared/CirclesMotionX4PopularWebsitesLR`));

const LazyLeavesAnimationAbs = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'LeavesAnimationAbsLazy' */ "../../_shared/leavesAnimation/LeavesAnimationAbs"));
const LazyLeavesInDropAnimation = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'LeavesInDropAnimationLazy' */ "../../_shared/leavesAnimation/LeavesInDropAnimation"));


const srcSvgPaper = "/sf/im/paper_ff5.svg"
const bowListOptionsHigh2 = {
    itemHeightPx: 100,
    listHeightPx: "auto",
    primarySecondarySeparator: "|",
}
const bowListOptionsAddedListBothBowsL = {
    bothBows: true,
    bowDirection: "left",
    motionDirection: "left",
    listPaddingLeftPx: "10",
    listPaddingRightPx: "10",
    listMarginBottomPx: "16",
}


const styleSxWrapContUp = {
    "&.container.conttitle": {

        position: "relative",
        pt: [ 2, 4, 6, 8 ],
        pb: 0,
        px: [ 0.5, 1, 2, 3 ],
        "& .wrapTitleBoost": {
            width: 1,
            "& .itemBL": {
                bgcolor: "secondary.dark",
                color: "primary.contrastText",

            },
        },
    },
    "& .square": {
        backgroundColor: (theme) => alpha(theme.palette.primary2.contrastText, 0.1),
        borderRadius: "50%",
    },
    "& .blank": {
        borderRadius: "50%",
        backgroundColor: (theme) => alpha(theme.palette.primary2.contrastText, 0.1),
    }
}

const styleSxWrapFeat = {
    "&.container.contFeat": {
        position: "relative",
        my: 0,
        py: [ 0, 0, 4, 6 ],
        px: [ 0.5, 1, 2, 3 ],

        "& .paper.MuiPaper-elevation1": {
            position: "relative",
            // padding: 4,
            py: [ 1, 2, 4 ],
            px: 0,
            textAlign: 'center',
            borderTopRightRadius: "25%",
            borderBottomLeftRadius: "25%",
            "& .MuiPaper-elevation1": {
                boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
            },
            "& .gridItem": {
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                "& .inGridItem": {
                    maxWidth: '100%',
                },
                "& .wrapLink": {
                    borderRadius: "255px 15px 225px 15px/15px 225px 15px 255px",
                    borderWidth: "2px",
                    borderColor: "error.dark",
                    borderStyle: "solid",
                    padding: "4px",
                    width: "90%",
                    ml: "auto",
                    mr: "auto",
                    '&:hover .MuiTypography-h6, &:hover .MuiSvgIcon-root, &:focus .MuiTypography-h6,&:focus .MuiSvgIcon-root': {
                        color: "primary.contrastText",
                        bgcolor: "primary.dark",
                        textDecoration: "none",
                    },
                    "& .link": {
                        textAlign: "center",

                        "& .linkText": {
                            bgcolor: "secondary.dark",
                            color: "secondary.contrastText",
                            "&.MuiTypography-root": {
                                position: "relative",
                                display: "inline-block",
                                boxShadow: 19,
                                fontSize: [ "medium", "large" ],

                                "&::before": {
                                    position: "absolute",
                                    left: 0,
                                    top: 0,
                                    bottom: 0,
                                    right: 0,

                                    bgcolor: "primary.main",
                                    opacity: 0.7,
                                    content: '""',
                                    clipPath: "circle(0% at 50% 50% )",
                                },
                                "&:hover": {
                                    "&::before": {
                                        content: '""',
                                        clipPath: "polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)",
                                    }
                                },
                                "&:active ::before": {
                                    content: '""',
                                    clipPath: "circle(100% at 50% 50% )",
                                },
                            }
                        },
                    },
                },
                wrapImgUnderLink: {
                    opacity: 0,
                    marginTop: 4,
                    marginBottom: 4,
                },
            },
        },
    },
}

const contInnerSep = {
    position: "relative",
    px: [ 0.5, 1, 2, 3 ],
    py: [ 1, 3, 4, 5 ],
    perspective: "1000px",
    my: 0,
}



const varInGridItem = ({ position }) => {
    const xFrom = position === "left" ? -200 : 200
    return {

        initial: false,
        animate: {
            opacity: 1,
            // scale: 1,
            y: [ xFrom, 0 ],
            x: [ xFrom, 0 ],
            transition: {
                ease: 'circInOut',
                duration: 0.2
            },
        },
        exit: {
            opacity: 0.1,
            y: xFrom,
            x: xFrom,
            transition: {
                ease: 'circInOut',
                duration: 0.1,
                when: "beforeChildren"
            },
        },
    }
}


const varIconSvgPath = ((prop) => {
    return {
        initial: false,
        animate: {
            scale: 1,
            strokeDashoffset: [ 0, 50 ],
            transition: {
                duration: 0.2
            }
        },
        exit: false,
        whileHover: {
            strokeWidth: 5,
            strokeDashoffset: [ 0, 50 ],
        },
        whileTap: {
            scale: 0.8,
            strokeWidth: 5,
            strokeDashoffset: [ 0, 50 ],
        },
    }
})


const varWrapImgUnderLink = (position) => {
    return {
        initial: false,
        animate1: {
            opacity: [ 0, 0.5, 1 ],
            perspective: [ 1000, 500, 0 ],
            rotateX: [ 180, 90, 0 ],
            transition: {
                type: 'spring',
                restSpeed: 0.01,
                stiffness: 50,
                mass: 0.9,
                damping: 300,
                bounce: 0.85,
                duration: 0.3,
            },
        },
        exit: false,
        whileHover: {
            originY: [ "100%", "100%", "100%", "100%", "0%", "0%", "0%", "0%" ],
            originX: [ "50%", "50%", "0%", "0%", "0%", "0%", "100%", "100%" ],
            rotateY: [ 180, 180, 180, 180, 0, 0, 0, 0 ],
            rotateX: [ 0, 0, 180, 180, 180, 180, 0, 0 ],
            transition: {
                duration: 5,
            }
        }
    }
}

const styleSxCarousel = {
    my: [ 2, 4, 6, 8 ],
    px: [ 0.5, 1, 2, 3 ],
    py: [ 2, 3, 4, 4, 4 ],
    background: (theme) => theme.gradients.lin3(0, theme.palette.primary2.main, "0%", theme.palette.primary2.light, "50%", theme.palette.primary2.main, "100%",),
}

const MainUp = props => {

    // const { title } = props
    const refBox = useRef(null);
    const refBox2 = useRef(null);
    const refBox3 = useRef(null);
    const theme = useTheme()
    const breakpoint = useBreakpoint()
    const isNotMobile = [ "md", "lg", "xl" ].includes(breakpoint)
    const circlesMotionWithWebsitesLRMem = useMemo(() => (
        <Suspense>
            <LazyCirclesMotionX4PopularWebsitesLR />
        </Suspense>
    ), [])
    const bgPrimaryDark = theme.palette.primary2.dark

    const optionsSvgInParentPaddingsAnimated = { strokeColor: "secondary", fillColor: "secondary" }
    const optionsSeparatorMovingImgOnPipe = {
        isHorizontal: true,
        imagesBgPipe1: [ imgBgPipe ],
        linGradient1_3Colors: [ theme.palette.secondary2.dark, alpha(theme.palette.secondary2.main, 0.2), theme.palette.primary2.main ],
        imgBg1Size: "250px 50px",
        pipe1Diameter: "60px",
        anim1DurationS: "5",
        isPipe2: true,
        imagesBgPipe2: [ imgBgPipe ],
        linGradient2_3Colors: [],
        imgBg2Size: "200px 50px",
        pipe2Diameter: "40px",
        isPipe2DirectionRev: false,
        anim2DurationS: "10",
        betweenPipe12: "10px",
    }


    const styleItemSecondary = {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.secondary.contrastText,
        boxShadow: theme.shadows[ 11 ],
        alignItems: 'center',
        flexDirection: 'row',
    }
    const styleListPrimary = {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        boxShadow: theme.shadows[ 21 ],
    }

    const styleLeftBorders20 = {
        borderTopLeftRadius: "20%",
        borderBottomLeftRadius: "20%",
    }
    const styleRightBorders20 = {
        borderTopRightRadius: "20%",
        borderBottomRightRadius: "20%",
    }

    const sepFillColor = theme.palette.primary2.dark
    const sepFillColorContrastText = theme.palette.primary2.contrastText

    return (
        <React.Fragment>
            {/* <Container
                maxWidth={false}
                className="container conttitle"
                sx={styleSxWrapContUp}
                key="conttitle"
            >
                <div
                    className="wrapTitleBoost"
                >
                    <AnimateScrollIntoViewportLazy
                        name="animTitle"
                        key="animTitle"
                    >
                        <AnimacieTitle title={title} breakpoint={breakpoint} />
                    </AnimateScrollIntoViewportLazy>
                </div>
            </Container> */}
            <Suspense>
                <LazySvgInParentPaddingsWithSettings />
            </Suspense>
            {/* <Demo /> */}
            <Suspense>
                <LazyDivsWithSvgInParentPaddingsViaMuiImageList />
            </Suspense>
            <AnimateScrollIntoViewport
                animateFramer={{
                    x: 0,
                    opacity: 1
                }}
                exitFramer={{ x: +200, opacity: 0 }}
                styleToMerge={{ opacity: 0 }}
                name="animScrollSvgInParentPaddingsAnimated"
                key="animScrollSvgInParentPaddingsAnimated"
            >
                <SeparatorAbsSvg2
                    name="sepNormalRel2FullHorizontalHills"
                    id="sepSvgInParentPaddingsAnimated"
                    optionsSep={{
                        styleSvg: { height: "100%", },
                        svgYPadding: 0,
                        isRelative: true,
                        key: "sepWrapAnimateSvg3dBall"
                    }} >
                    {/* <div
                        style={{ position: "relative", backgroundColor: sepFillColor, color: sepFillColorContrastText }}
                        key="divSvgInParentPaddingsAnimated"
                    > */}
                    <Container
                        maxWidth={'lg'}
                    >
                        <Box
                            ref={refBox2}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '500px', // '100vh',
                                textAlign: 'center',
                                // padding: 0, // 3,
                                position: 'relative',
                                width: '100%',
                                borderStyle: 'groove',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                mt: '20px',
                                pl: '100px',
                                pr: '100px',
                                pt: '150px',
                                pb: '150px',
                                bgcolor: 'primary.main',
                                color: 'primary.contrastText',
                            }}
                        >
                            {/* <AnimatedSvgInsideParentBoundingBox2 refBox={refBox} /> */}
                            {/* <PaintSvgOnAppearingSvgPaths /> */}
                            <Suspense>
                                <LazySvgInParentPaddingsAnimated refParent={refBox2} options={optionsSvgInParentPaddingsAnimated} />
                            </Suspense>
                            {/* <SvgInParentPaddings refParent={refBox}
                    options={{
                        topWavesNumber: 13,
                        rightWavesNumber: 13,
                        bottomWavesNumber: 13,
                        leftWavesNumber: 13,
                        wavesFillColor: theme.palette.primary.main,
                        wavesStrokeColor: theme.palette.primary.contrastText
                    }}
                /> */}
                            <Box sx={{
                                bgcolor: 'primary2.main',
                                color: 'primary2.contrastText',
                                height: '100%',
                                width: '100%',
                                alignContent: 'center',
                            }}>
                                <img src={jpgVodaVlese11dusty} alt="voda v lese" style={{
                                    position: 'static',
                                    height: '100%',
                                    objectFit: 'contain',
                                }}
                                    loading="lazy"
                                />
                            </Box>
                        </Box>
                    </Container>
                </SeparatorAbsSvg2>
            </AnimateScrollIntoViewport>
            <AnimateScrollIntoViewport
                animateFramer={{
                    x: 0,
                    opacity: 1
                }}
                exitFramer={{ x: +200, opacity: 0 }}
                styleToMerge={{ opacity: 0 }}
                name="animScrollGridDivsWithBgGradientAnimatedViaMuiImageList"
                key="animScrollGridDivsWithBgGradientAnimatedViaMuiImageList"
            >
                {/* <SeparatorAbsSvg2Lazy
                    name="sepNormalRel2FullHorizontalHills"
                    id="sepGridDivsWithBgGradientAnimatedViaMuiImageList"
                    optionsSep={{
                        styleSvg: { height: "100%", },
                        svgYPadding: 0,
                        isRelative: true,
                        key: "sepGridDivsWithBgGradientAnimatedViaMuiImageList"
                    }} > */}
                <div
                    style={{ position: "relative", backgroundColor: sepFillColor, color: sepFillColorContrastText }}
                    key="divSvgInParentPaddingsAnimated"
                >
                    <Grid container
                        maxWidth="md"
                        sx={{
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            alignItems: 'center',
                        }}
                    >
                        <Grid item xs={12} sm={6} md={8}>
                            {/* <Card sx={{
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', borderRadius: '50%',
            overflow: 'hidden',
            backgroundColor: 'unset'
          }}>
            <DivsWithBgGradientAnimatedViaMuiImageList name="first" />
          </Card> */}
                            <Card sx={{
                                boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', borderRadius: '50%',
                                overflow: 'hidden',
                                backgroundColor: 'unset'
                            }}>
                                <Suspense>
                                    <LazyDivsWithBgGradientAnimatedViaMuiImageList name="first" />
                                </Suspense>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {/* <div className="helloWrap" style={{
            // position: "relative",
            // display: 'flex', 
            // alignItems: 'center',
            // maxWidth: "50%",
            // height: "200px",
            // maxHeight: "200px",
          }}>
            <ImageWithClipPathAnimations clipPathId="helloClip" srcImg={srcImgWeb4} clipPathFrom={hello_b_svgPathsDReversed[ 0 ]} clipPathTo={[ ...hello_b_svgPathsDReversed ]} styleWrap={{ width: "400px" }} />
          </div> */}
                            {/* <DivsWithBgGradientAnimatedViaMuiImageList name="second" /> */}
                            <Suspense>
                                <LazyDivWithCircleAndCirclesAroundClipPathAnimation />
                            </Suspense>

                        </Grid>
                    </Grid>
                </div>
                {/* </SeparatorAbsSvg2Lazy> */}
            </AnimateScrollIntoViewport>
            {/* <Container
                maxWidth={'lg'}
            >
                <Box
                    ref={refBox}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '500px', // '100vh',
                        textAlign: 'center',
                        position: 'relative',
                        width: '100',
                        borderStyle: 'groove',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        mt: '20px',
                        backgroundColor: 'lightsalmon',
                        pl: '100px',
                        pr: '100px',
                        pt: '150px',
                        pb: '150px',
                    }}
                >
            <SvgInParentPaddings refParent={refBox}
                options={{
                    topWavesNumber: 13,
                    rightWavesNumber: 13,
                    bottomWavesNumber: 13,
                    leftWavesNumber: 13,
                    wavesFillColor: theme.palette.primary.main,
                    wavesStrokeColor: theme.palette.primary.contrastText
                }}
            />
            <Box sx={{
                bgcolor: 'primary2.main',
                color: 'primary2.contrastText',
                height: '100%',
                width: '100%',
                alignContent: 'center',
            }}>
                <img src={jpgVodaVlese11dusty} alt="voda v lese" style={{
                    position: 'static',
                    height: '100%',
                    objectFit: 'contain',
                }} />
            </Box>
        </Box>
            </Container >  */}
            {/* <AnimatedSvgInsideParentBoundingBox2 refBox={refBox} /> */}
            {/* <AnimatedSvgInsideParentBoundingBox3 refBox={refBox} /> */}
            {/* <SvgInParentPaddingsAnimated refParent={refBox} /> */}
            <Container maxWidth={'lg'}
                sx={{
                    display: { xs: 'none', sm: 'block' },
                }}
            >
                <Box
                    ref={refBox3}
                    className="clay"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '500px', // '100vh',
                        textAlign: 'center',
                        // padding: 0, // 3,
                        position: 'relative',
                        width: '100%',
                        borderStyle: 'groove',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        mt: '20px',
                        backgroundColor: 'secondary.main',
                        color: 'secondary.contrastText',
                        pl: '10px',
                        pr: '10px',
                        pt: '15px',
                        pb: '15px',
                    }}
                >
                    <Suspense>
                        <LazyPaintSvgWithMouse />
                    </Suspense>
                </Box>
            </Container>
            <Container
                maxWidth={false}
                component="section"
                sx={{
                    mx: 0,
                    my: 0,
                    px: [ 0.5, 1, 2, 3 ],
                    py: [ 0, 0, 4, 4, 4 ],
                    background: (theme) => theme.gradients.lin3(0, theme.palette.primary2.main, "0%", theme.palette.primary2.light, "50%", theme.palette.primary2.main, "100%",),
                    width: 'inherit',
                }}
            >
                <AnimateScrollIntoViewport
                    durationIn={0.5}
                    durationOut={0.2}
                    animateFramer={{
                        clipPath: POLYGON_RECTANGLE, // POLYGON_RECTANGLE_10_POINTS_LEFT, 
                        // backgroundColor: alpha(bgPrimaryDark, 0),
                    }}
                    exitFramer={{
                        clipPath: POLYGON_RECTANGLE_RIGHT_NULL, // POLYGON_STAR, 
                        // backgroundColor: alpha(bgPrimaryDark, 0.8),
                    }}
                    styleToMerge={{
                        clipPath: POLYGON_RECTANGLE_RIGHT_NULL, // POLYGON_STAR, 
                        // backgroundColor: alpha(bgPrimaryDark, 0.8),
                    }}
                    name="scrollAbsImagesAnimation"
                    key="ScrollAbsImagesAnimation"
                >

                    <SeparatorAbsSvg2
                        name="sepNormalRel2FullHorizontalHills"
                        id="AbsImages"
                        optionsSep={{
                            dPathOrderNotIn: [ 0 ],
                            styleSvg: {
                                height: "10px",
                                transform: 'rotateY(180deg)'
                            },
                            svgYPadding: 0,
                            isRelative: true,
                        }} >

                        <Container
                            maxWidth={false}
                            style={{ position: "relative", backgroundColor: sepFillColor }}
                        >
                            <Suspense>
                                <LazyAbsImagesAnimation />
                            </Suspense>
                        </Container>
                    </SeparatorAbsSvg2>
                </AnimateScrollIntoViewport>
            </Container>

            <AnimateScrollIntoViewport
                animateFramer={{
                    x: 0, //[ -200, 0 ], 
                    opacity: 1
                }}
                exitFramer={{ x: -200, opacity: 0 }}
                styleToMerge={{ opacity: 0 }}
                name="CometWithForm"
                key='CometWithForm'
            >
                <SeparatorAbsSvg2
                    name="sepNormalRel2FullHorizontalHills"
                    id="sepCometWithForm"
                    optionsSep={{
                        dPathOrderNotIn: [ 2 ],
                        styleSvg: {
                            height: "40px",
                            transform: 'rotateY(180deg)'
                        },
                        svgYPadding: 0,
                        isRelative: true,
                    }} >
                    <div
                        style={{ position: "relative", backgroundColor: sepFillColor, color: sepFillColorContrastText }}
                    >
                        <Container maxWidth="lg">
                            {breakpoint === "xs" ? (
                                <Suspense>
                                    <LazyCometWithForm>
                                        <p>Dobré ráno</p>
                                        <img src={imgGearWheelA} alt="" loading="lazy" />
                                    </LazyCometWithForm>
                                </Suspense>
                            ) :
                                (
                                    <Suspense>
                                        <LazyAnimatingColumnsOrderingWithFlip key="flipCometWithForm">
                                            <LazyCometWithForm>
                                                <p>Dobré ráno</p>
                                                <img src={imgGearWheelA} alt="" loading="lazy" />
                                            </LazyCometWithForm>
                                        </LazyAnimatingColumnsOrderingWithFlip>
                                    </Suspense>
                                )
                            }
                        </Container>
                    </div>
                </SeparatorAbsSvg2>
            </AnimateScrollIntoViewport>
            <AnimateScrollIntoViewport
                animateFramer={{
                    x: 0, //[ -200, 0 ], 
                    opacity: 1
                }}
                exitFramer={{ x: -200, opacity: 0 }}
                styleToMerge={{ opacity: 0 }}
                name="Comet_WithFormMuiDrawer"
                key='CometWithFormMuiDrawer'
            >
                <SeparatorAbsSvg2
                    name="sepNormalRel2FullHorizontalHills"
                    id="AnimatingCometWithFormMuiDrawer"
                    optionsSep={{
                        dPathOrderNotIn: [ 2 ],
                        styleSvg: {
                            height: "40px",
                            transform: 'rotateY(180deg)'
                        },
                        svgYPadding: 0,
                        isRelative: true,
                    }} >
                    <div
                        style={{
                            position: "relative", backgroundColor: sepFillColor, color: sepFillColorContrastText

                        }}
                    >
                        <Container maxWidth="lg" >
                            {breakpoint === "xs" ? (
                                <Suspense>
                                    <LazyCometWithFormMuiDrawer>
                                        <p>web7</p>
                                        <img src={imgGearWheelA} alt="" loading="lazy" />
                                    </LazyCometWithFormMuiDrawer>
                                </Suspense>
                            ) : (
                                <Suspense>
                                    <LazyAnimatingColumnsOrderingWithFlip key="flipCometWithFormMuiDrawer" >
                                        <LazyCometWithFormMuiDrawer>
                                            <p>web7</p>
                                            <img src={imgGearWheelA} alt="" loading="lazy" />
                                        </LazyCometWithFormMuiDrawer>
                                    </LazyAnimatingColumnsOrderingWithFlip>
                                </Suspense>
                            )}
                        </Container>
                    </div>
                </SeparatorAbsSvg2>
            </AnimateScrollIntoViewport>
            <AnimateScrollIntoViewport
                animateFramer={{
                    x: 0,
                    opacity: 1
                }}
                exitFramer={{ x: +200, opacity: 0 }}
                styleToMerge={{ opacity: 0 }}
                name="AnimateSvg3dBall"
                key="AnimateSvg3dBall"
            >
                <SeparatorAbsSvg2
                    name="sepNormalRel2FullHorizontalHills"
                    id="AnimatingColumnsOrderingWithFlip"
                    optionsSep={{
                        styleSvg: { height: "100%", },
                        svgYPadding: 0,
                        isRelative: true,
                        key: "sepWrapAnimateSvg3dBall"
                    }} >
                    <div
                        style={{ position: "relative", backgroundColor: sepFillColor, color: sepFillColorContrastText }}
                        key="cont3dball"
                    >
                        <Container maxWidth="lg" >
                            <Suspense>
                                <LazyAnimateSvg3dBallWithMuiFormOnDrawer />
                            </Suspense>
                        </Container>
                    </div>
                </SeparatorAbsSvg2>
            </AnimateScrollIntoViewport>
            <Container
                maxWidth={false}
                className="container contFeat"
                sx={styleSxWrapFeat}
                key="contFeat"
            >
                <SeparatorAbsSvg2
                    name="sepNormalRel2FullHorizontalHills"
                    id="CircularMenuNature"
                    passedControllingInViewport={false}
                    optionsSep={{
                        dPathOrderNotIn: [ 0 ],
                        styleSvg: {
                            height: '50px',
                        },
                        svgYPadding: 0,
                        isRelative: true,
                        key: "sepWrapCircularMenuNature"
                    }}

                >
                    <div
                        style={{ position: "relative", backgroundColor: sepFillColor, color: sepFillColorContrastText }}
                    >
                        <Container maxWidth="lg" >
                            <div className="paper MuiPaper-elevation1"                    >
                                <Grid container spacing={0} >
                                    <Grid item xs={12} md={6}
                                        className="gridItem"
                                        key="grid-1">
                                        <motion.div
                                            variants={varInGridItem({ position: "left" })}
                                            className="inGridItem"
                                            initial="initial"
                                            animate="animate"
                                            key="varInGridItem-left"
                                        >
                                            <BowListLazy
                                                name="BL-mainUp-webPages"
                                                options={{
                                                    stopItemUseEffectAnimation: true,
                                                    ...bowListOptionsHigh2,
                                                    ...bowListOptionsAddedListBothBowsL,
                                                    styleList: { ...styleListPrimary, ...styleRightBorders20, ...styleLeftBorders20 },
                                                    styleItem: { ...styleItemSecondary }
                                                }}
                                                key="BL-mainUp-webPages">
                                                {[ <div
                                                    key="link1"
                                                    className="wrapLink"
                                                >
                                                    <Link to="/tvorba-web-stranky/"
                                                        className="link"
                                                        key="linklink1"
                                                    >
                                                        <div>
                                                            <HttpIcon />
                                                            <Typography
                                                                // variant="h2"
                                                                className="linkText"
                                                            // sx={{ fontSize: [ "1rem", "1rem", "1.5rem", "1.5rem" ] }}
                                                            >
                                                                Tvorba web stránok
                                                            </Typography>
                                                        </div>
                                                    </Link>
                                                </div>,
                                                ]}
                                            </BowListLazy>
                                            <AnimateScrollIntoViewport
                                                animateFramer={{
                                                    x: 0, // [ -200, 0 ], 
                                                    opacity: 1
                                                }}
                                                exitFramer={{ x: -200, opacity: 0 }}
                                                styleToMerge={{ opacity: 0 }}
                                                name="LazyCircularMenuNature"
                                            >
                                                <Suspense
                                                // fallback={<div>Loading LazyCircularMenuNature ...</div>}
                                                >
                                                    <LazyCircularMenuNature />
                                                </Suspense>
                                            </AnimateScrollIntoViewport>
                                        </motion.div>
                                    </Grid>
                                    <Grid item xs={12} md={6}
                                        className="gridItem"
                                        key="grid-2"
                                    >
                                        <motion.div
                                            variants={varInGridItem({ position: "right" })}
                                            className="inGridItem"
                                            initial="initial"
                                            animate="animate"
                                            key="varInGridItem-right"
                                        >
                                            <BowListLazy
                                                name="BL-mainUp-anim-exc"
                                                options={{
                                                    stopItemUseEffectAnimation: true,
                                                    ...bowListOptionsHigh2,
                                                    ...bowListOptionsAddedListBothBowsL,
                                                    styleList: { ...styleListPrimary, ...styleRightBorders20, ...styleLeftBorders20 },
                                                    styleItem: { ...styleItemSecondary }
                                                }}
                                                key="BL-mainUp-anim-exc"
                                            >
                                                {[ <div
                                                    key="link2"
                                                    className="wrapLink"
                                                >
                                                    <Link
                                                        to="/programovanie-excel/"
                                                        className="link"
                                                        key="linklink2"
                                                    >
                                                        <div>
                                                            <SvgIcon>
                                                                <motion.path d="M23.5,3H14V.5A.5.5,0,0,0,13.41,0l-13,2.5A.5.5,0,0,0,0,3V21a.5.5,0,0,0,.41.49l13,2.5h.09a.5.5,0,0,0,.5-.5V21h9.5a.5.5,0,0,0,.5-.5V3.5A.5.5,0,0,0,23.5,3ZM8.95,16.28a.5.5,0,0,1-.89.45L6.5,13.62,4.95,16.72a.5.5,0,0,1-.89-.45L5.94,12.5,4.05,8.72a.5.5,0,0,1,.89-.45L6.5,11.38,8.05,8.28a.5.5,0,0,1,.89.45L7.06,12.5ZM23,20H14V19h2.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H14V16h2.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H14V13h2.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H14V10h2.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H14V7h2.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H14V4h9Z"
                                                                    variants={varIconSvgPath()}
                                                                    strokeDasharray="01"
                                                                    strokeLinecap="round"
                                                                    fill="none"
                                                                    strokeWidth={2}
                                                                    stroke={theme.palette.primary.main}
                                                                    pathLength={50}
                                                                    key="BL-mainUp-anim-exc-iconpath"
                                                                />
                                                            </SvgIcon>
                                                            <Typography
                                                                // variant="h2"
                                                                className="linkText"
                                                            >
                                                                Programovanie pre excel
                                                            </Typography>
                                                        </div>
                                                    </Link>
                                                </div>,
                                                ]}
                                            </BowListLazy>
                                            {/* <AnimateScrollIntoViewportLazy
                                                animateFramer={{
                                                    x: 0, // [ 200, 0 ], 
                                                    opacity: 1
                                                }}
                                                exitFramer={{ x: 200, opacity: 0 }}
                                                styleToMerge={{ opacity: 0 }}
                                                name="view-excImg">

                                                <motion.div
                                                    variants={varWrapImgUnderLink("right")}
                                                    whileHover="whileHover"
                                                    className="wrapImgUnderLink"
                                                    style={{ scale: 1, originX: "50%", originY: "50%", rotateX: 0, rotateY: 0 }}
                                                    key="varWrapImgUnderLink-imgExc"
                                                >
                                                    <ExcelImage />
                                                </motion.div>
                                            </AnimateScrollIntoViewportLazy> */}
                                        </motion.div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Container>
                    </div>
                </SeparatorAbsSvg2>
            </Container>
            <SeparatorAbsSvg2
                name="sepNormalRel2FullHorizontalHills"
                id="mask-jpgVodaVlese11dusty"
                passedControllingInViewport={false}
                key="mask-jpgVodaVlese11dusty"
                optionsSep={{
                    dPathOrderNotIn: [ 1 ],
                    styleSvg: {
                        height: "100px",
                        transform: 'rotateY(180deg)'
                    },
                    svgYPadding: 0,
                    isRelative: true,
                }} >
                <div
                    style={{ position: "relative", backgroundColor: sepFillColor, color: sepFillColorContrastText }}
                >
                    <Container maxWidth="lg" >
                        <Suspense>
                            <LazyMaskingChildren>
                                <AnimateScrollIntoViewport
                                    durationIn={0.5}
                                    durationOut={0.2}
                                    animateFramer={{ clipPath: POLYGON_RECTANGLE_10_POINTS_RIGHT, }}
                                    exitFramer={{ clipPath: POLYGON_STAR, }}
                                    styleToMerge={{ clipPath: POLYGON_STAR, }}
                                    name="maskImg"
                                    key="maskImg"
                                >
                                    <img
                                        src={jpgVodaVlese11dusty} alt="water in forest" loading="lazy"
                                    />
                                </AnimateScrollIntoViewport>
                            </LazyMaskingChildren>
                        </Suspense>
                    </Container>
                </div>
            </SeparatorAbsSvg2>
            <Suspense>
                <LazySeparatorMovingImgOnPipe {...optionsSeparatorMovingImgOnPipe} />
            </Suspense>
            {
                isNotMobile && (
                    <AnimateScrollIntoViewport
                        styleToMergeImportant={{ overflow: "visible" }}
                        name="LazyUpright"
                        key="LazyUpright"
                    >
                        <div
                            style={{
                                display: 'inline',
                                width: '100%'
                            }}
                            key="avat"
                        >
                            <div
                                className="av1"
                                style={{
                                    width: '50px',
                                    position: 'relative',
                                    display: 'block',
                                    left: '10%',
                                    height: '0',
                                }}
                                key="av1"
                            >
                                <Suspense>
                                    <LazyUprightAvatarsMoving />
                                </Suspense>
                            </div>

                            <div
                                className="av2"
                                style={{
                                    width: '50px',
                                    position: 'relative',
                                    display: 'block',
                                    left: '100%',
                                    height: '0',
                                }}
                            >
                                <Suspense>
                                    <LazyUprightAvatarsMoving />
                                </Suspense>
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'inline',
                                width: '100%'
                            }}
                            key="upright"
                        >
                            <div style={{
                                width: '0px',
                                position: 'relative',
                                display: "inline",
                                left: '80%',
                                height: '0px'
                            }}>
                                <Suspense>
                                    <LazyUpright3dSvgsMoving />
                                </Suspense>
                            </div>
                        </div>
                    </AnimateScrollIntoViewport>
                )
            }
            <AnimateScrollIntoViewport
                durationIn={0.5}
                durationOut={0.2}
                animateFramer={{ clipPath: POLYGON_RECTANGLE_10_POINTS_RIGHT, }}
                exitFramer={{ clipPath: POLYGON_STAR, }}
                styleToMerge={{ clipPath: POLYGON_STAR, }}
                name="animWavePathBox "
            >
                <SeparatorAbsSvg2
                    name="sepNormalRel2FullHorizontalHills"
                    id="WavePathBox"
                    optionsSep={{
                        styleSvg: {
                            height: "100%",
                        },
                        svgYPadding: 0,
                        isRelative: true,
                        key: "sepWavePathBox "
                    }} >

                    <div style={{ position: "relative", backgroundColor: sepFillColor, color: sepFillColorContrastText }}>
                        <Container maxWidth="lg" >
                            <Suspense>
                                <LazyWavePathBox />
                            </Suspense>
                        </Container>
                    </div>
                </SeparatorAbsSvg2>
            </AnimateScrollIntoViewport>
            <AnimateScrollIntoViewport
                durationIn={0.5}
                durationOut={0.2}
                animateFramer={{ clipPath: POLYGON_RECTANGLE_10_POINTS_RIGHT, }}
                exitFramer={{ clipPath: POLYGON_STAR, }}
                styleToMerge={{ clipPath: POLYGON_STAR, }}
                name="atroTree_1"
            >
                <SeparatorAbsSvg2
                    name="sepNormalRel2FullHorizontalHills"
                    id="AtroposEffectTree-1"
                    optionsSep={{
                        styleWrapSep: { marginBottom: '0', },
                        styleWrapDown: { backgroundColor: "maroon", },
                        styleSvg: { height: "100%", },
                        svgYPadding: 0,
                        isRelative: true,
                        isDouble: true,
                        isQuatro: false,
                        key: "sepNormalRel2FullHorizontalHills_1_atro"
                    }} >
                </SeparatorAbsSvg2>
                <div
                    style={{ backgroundColor: "maroon" }}
                >
                    <Container maxWidth="lg"
                        sx={contInnerSep}
                    >
                        <AtroposEffectTreeLazy />
                        {/* <Suspense>
                            <LazyAtroposEffectTree />
                        </Suspense> */}
                    </Container>
                </div>
                <SeparatorAbsSvg2
                    name="sepNormalRel2FullHorizontalHills"
                    id="AtroposEffectTree-2"
                    optionsSep={{
                        styleWrapSep: { marginTop: '0', },
                        styleWrapDbl: { backgroundColor: "maroon" },
                        styleSvg: { height: "100%", },
                        svgYPadding: 0,
                        isRelative: true,
                        isDouble: true,
                        isQuatro: false,
                        key: "sepMain2g"
                    }} >
                </SeparatorAbsSvg2>
            </AnimateScrollIntoViewport>

            <AnimateScrollIntoViewport
                durationIn={0.2}
                durationOut={0.2}
                animateFramer={{
                    rotateZ: 0
                }}
                exitFramer={{
                    rotateZ: 15
                }}
                styleToMerge={{
                    rotateZ: 15
                }}
                name="WavePathBoxCircleList"
            >
                <SeparatorAbsSvg2
                    name="sepNormalRel2FullHorizontalHills"
                    id="CircleList"
                    optionsSep={{
                        dPathOrderNotIn: [ 1 ],
                        styleSvg: {
                            height: "100px",
                            transform: 'rotateY(180deg)'
                        },
                        svgYPadding: 0,
                        isRelative: true,
                    }} >
                    <div
                        style={{ position: "relative", backgroundColor: sepFillColor, color: sepFillColorContrastText }}
                    >

                        <Container maxWidth="lg">
                            <Grid
                                className='contwave'
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                style={{
                                    marginTop: 0, marginBottom: 0, borderRadius: 'unset',
                                }}
                            >
                                <Grid item xs={12} sm={8}
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                    key="g1">
                                    <Suspense>
                                        <LazyCircleList />
                                    </Suspense>
                                </Grid>
                                <Grid item xs={12} sm={4} key="g2">
                                    <Suspense>
                                        <LazyAnimateItemsFromCentreToAroundCircleAndTimeoutBack />
                                    </Suspense>
                                </Grid>
                            </Grid>
                        </Container>
                    </div>
                </SeparatorAbsSvg2>
            </AnimateScrollIntoViewport>
            {
                isNotMobile &&
                <AnimateScrollIntoViewport
                    durationIn={0.5}
                    durationOut={0.2}
                    animateFramer={{ clipPath: POLYGON_RECTANGLE_10_POINTS_RIGHT, }}
                    exitFramer={{ clipPath: POLYGON_STAR, }}
                    styleToMerge={{ clipPath: POLYGON_STAR, }}
                    name="sepMainWaveAfter"
                >
                    <SeparatorAbsSvg2
                        name="sepNormalRelDblFullHorizontal1"
                        id="sepNormalRelDblFullHorizontal1"
                        optionsSep={{
                            dPathOrderNotIn: [ 0 ], isRotateYReverse: true, heightsWanted: [ 8, 16, 32, 48, 64 ],
                            svgYPadding: -100,
                            key: "sepMainWaveAfter"
                        }} />
                </AnimateScrollIntoViewport>
            }


            {
                isNotMobile && <Container
                    maxWidth="lg" //{false}
                    className="cont cont3D"
                    key="cont3D_a"
                >
                    <AnimateScrollIntoViewport
                        name="LazySeparatorMovingImgOnPipe"
                    >
                        <LazySeparatorMovingImgOnPipe {...optionsSeparatorMovingImgOnPipe} key="csssep" />
                        {/* <div key="emMulti">🖅🏰🏕️🏖️🗼🌃🌄🌉🌇</div>
                        <div key="em">🗼</div>
                        '🖅🏰🏕️🏖️🗼🌃🌄🌉🌇'
                        <motion.div key="11111">
                            <img src={imgLeaf1} alt="list zo stromu" loading="lazy" />
                        </motion.div>

                        <motion.div key="2222">
                            <img src={imgLeaf1} alt="list zo stromu" loading="lazy" />
                        </motion.div>

                        <DropEffect {...optionsDropEffect.diffBordersBand} boxShadowColor="white" key="3333" >
                            <img src={imgLeaf1} alt="list zo stromu" loading="lazy" />
                        </DropEffect> */}
                        {/* <Suspense>
                            <LazyCyclingRainbowColorsCssPropertiesInjector />
                        </Suspense> */}
                    </AnimateScrollIntoViewport>
                </Container>
            }

            {
                isNotMobile &&
                <div
                    style={{
                        transform: "translateZ(0)",
                        minHeight: '100vh',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}
                // ref={refRychle}
                // id="rychle2"
                >
                    <AnimateScrollIntoViewport name="parAnim">
                        <SeparatorAbsSvg2
                            name="sepNormalRel2FullHorizontalHills"
                            id="ParallaxMotion"
                            optionsSep={{
                                styleSvg: {
                                    height: "100px",
                                    transform: 'rotateY(180deg)'
                                },
                                svgYPadding: 0,
                                isRelative: true,
                            }} >
                            <div
                                style={{ position: "relative", backgroundColor: sepFillColor, color: sepFillColorContrastText }}
                            >
                                <Container maxWidth="md">
                                    <Suspense fallback={<div>Loading ParallaxMotion ...</div>}>
                                        <LazyParallaxMotion />
                                    </Suspense>
                                </Container>
                            </div>
                        </SeparatorAbsSvg2>
                    </AnimateScrollIntoViewport>
                </div>
            }
            {
                isNotMobile && <>
                    <AnimateScrollIntoViewport
                        durationIn={0.5}
                        durationOut={0.2}
                        animateFramer={{ clipPath: POLYGON_RECTANGLE_10_POINTS_RIGHT, }}
                        exitFramer={{ clipPath: POLYGON_STAR, }}
                        styleToMerge={{ clipPath: POLYGON_STAR, }}
                        name="LazyAtroposEffectHillyLandWithTrees"
                    >
                        <SeparatorAbsSvg2
                            name="sepNormalRel2FullHorizontalHills"
                            id="LazyAtroposEffectHillyLandWithTrees"
                            optionsSep={{
                                dPathOrderNotIn: [ 2 ],
                                styleWrapSep: {
                                    marginTop: '0',
                                    marginBottom: '0',
                                },
                                styleSvg: {
                                    height: "40px",
                                    transform: 'rotateY(180deg)'
                                },
                                svgYPadding: 0,
                                isRelative: true,
                            }} >
                            <div
                                style={{
                                    position: "relative",
                                    backgroundColor: sepFillColor, color: sepFillColorContrastText
                                }}
                            >
                                <Container
                                    maxWidth="xl"
                                    className='cont'
                                >
                                    <FrameMaskForDiv>
                                        <Suspense fallback="...loading">
                                            <LazyAtroposEffectHillyLandWithTrees />
                                        </Suspense>
                                    </FrameMaskForDiv>
                                </Container>
                            </div>
                        </SeparatorAbsSvg2>
                    </AnimateScrollIntoViewport>
                </>
            }
            {
                isNotMobile &&
                <AnimateScrollIntoViewport
                    styleToMerge={{ backgroundImage: `url(${srcSvgPaper})`, backgroundRepeat: 'round', opacity: 0 }}
                    name="NatureCardsAroundEllipseMotion"
                >
                    <SeparatorAbsSvg2
                        name="sepNormalRel2FullHorizontalHills"
                        id="LazyNatureCardsAroundEllipseMotion"
                        optionsSep={{
                            styleSvg: { height: "100px", },
                            svgYPadding: 0,
                            isRelative: true,
                        }} >
                        <div
                            style={{ position: "relative", backgroundColor: sepFillColor, color: sepFillColorContrastText }}
                        >
                            <Container maxWidth="lg">
                                <Suspense fallback={<div>Loading NatureCardsAroundEllipseMotion ...</div>}>
                                    <LazyNatureCardsAroundEllipseMotion />
                                </Suspense>
                            </Container>
                        </div>
                    </SeparatorAbsSvg2>
                </AnimateScrollIntoViewport>
            }




            <SeparatorAbsSvg2
                name="sepNormalRel2FullHorizontalHills"
                id="LazyLeavesAnimation"
                passedControllingInViewport={false}
                optionsSep={{
                    styleSvg: {
                        height: "150px",
                        transform: 'rotateY(180deg)'
                    },
                    svgYPadding: 0,
                    isRelative: true,
                }} >
                <div
                    style={{ position: "relative", backgroundColor: sepFillColor, color: sepFillColorContrastText }} >
                    <Container maxWidth="lg"                >
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            style={{ marginTop: 0, marginBottom: 0, borderRadius: 'unset' }}
                        >
                            <Grid item xs={12} sm={8} className={`leaves`} key="leaves">
                                <AnimateScrollIntoViewport
                                    durationIn={0.5}
                                    durationOut={0.2}
                                    animateFramer={{ clipPath: POLYGON_RECTANGLE_10_POINTS_RIGHT, }}
                                    exitFramer={{ clipPath: POLYGON_STAR, }}
                                    styleToMerge={{ backgroundImage: `url(${srcSvgPaper})`, backgroundRepeat: 'round', clipPath: POLYGON_STAR, }}
                                    name="LazyLeavesAnimation"
                                >
                                    <Suspense fallback={<div>Loading LazyLeavesAnimation ...</div>}>
                                        <LazyLeavesAnimation />
                                    </Suspense>
                                </AnimateScrollIntoViewport>
                            </Grid>
                            <Grid item xs={12} sm={4} key="gifTree">
                                <AnimateScrollIntoViewport
                                    animateFramer={{
                                        x: "0%",
                                        opacity: 1
                                    }}
                                    exitFramer={{ x: "100%", opacity: 0 }}
                                    styleToMerge={{ display: "flex", justifyContent: 'flex-start', flexDirection: "row", opacity: 0 }}
                                    name="wave"
                                >
                                    <img loading="lazy" src="/sf/anim/tree_12col-simpl-4-bezBg2.LSR-484x484-q2-phot.gif" alt="tree in wind animation" style={{ width: "inherit" }} />
                                </AnimateScrollIntoViewport>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </SeparatorAbsSvg2>
            <AnimateScrollIntoViewport
                animateFramer={{
                    x: "0%",
                    opacity: 1
                }}
                exitFramer={{ x: "100%", opacity: 0 }}
                styleToMerge={{ display: "flex", justifyContent: 'flex-start', flexDirection: "row", backgroundImage: `url(${srcSvgPaper})`, backgroundRepeat: 'round', opacity: 0 }}
                name="treesOnWavesMove" key="treesOnWavesMove" >
                <SeparatorAbsSvg2
                    name="sepNormalRel2FullHorizontalHills"
                    id="img"
                    optionsSep={{
                        dPathOrderNotIn: [ 2 ],
                        styleSvg: { height: "50px", },
                        svgYPadding: 0,
                        isRelative: true,
                    }} >
                    <div
                        style={{ position: "relative", backgroundColor: sepFillColor, color: sepFillColorContrastText }}
                    >
                        <Container maxWidth="md">
                            <img loading="lazy" src="/sf/anim/waves4WithTrees-mark-highTree-736x103-xMove-iba4x-q100.gif" alt="Trees on Waves moving horizontally" style={{ width: "inherit" }} />
                        </Container>
                    </div>
                </SeparatorAbsSvg2>
            </AnimateScrollIntoViewport>
            {
                isNotMobile &&

                <AnimateScrollIntoViewport name="HotelCardsAroundEllipseMotionWithData"                   >
                    <SeparatorAbsSvg2
                        name="sepNormalRel2FullHorizontalHills"
                        id="LazyHotelCardsAroundEllipseMotionWithData"
                        optionsSep={{
                            styleSvg: {
                                height: "100px",
                            },
                            svgYPadding: 0,
                            isRelative: true,
                        }} >
                        <div
                            style={{ position: "relative", backgroundColor: sepFillColor, color: sepFillColorContrastText }}
                        >
                            <Container maxWidth="lg">
                                <Suspense fallback={<div>Loading HotelCardsAroundEllipseMotionWithData ...</div>}>
                                    <LazyHotelCardsAroundEllipseMotionWithData />
                                </Suspense>
                            </Container>
                        </div>
                    </SeparatorAbsSvg2>
                </AnimateScrollIntoViewport>
            }
            <AnimateScrollIntoViewport
                durationIn={0.5}
                durationOut={0.2}
                animateFramer={{ clipPath: POLYGON_RECTANGLE_10_POINTS_RIGHT, }}
                exitFramer={{ clipPath: POLYGON_STAR, }}
                styleToMerge={{ clipPath: POLYGON_STAR, }}
                name="treesOnWavesMoveInFrame"
            >
                <SeparatorAbsSvg2
                    name="sepNormalRel2FullHorizontalHills"
                    id="img2"
                    optionsSep={{
                        dPathOrderNotIn: [ 0 ],
                        styleSvg: { height: "50px", },
                        svgYPadding: 0,
                        isRelative: true,
                    }} >
                    <div style={{ position: "relative", backgroundColor: sepFillColor, color: sepFillColorContrastText }}                    >
                        <Container maxWidth="md">
                            <img loading="lazy" src="/sf/anim/waves4WithTrees-mark-highTree-podNadtien-736x175-anim-move-iba4x-2fra-boomerang-phot.gif" alt="Trees on Waves moving horizontally" style={{ width: "inherit" }} />
                        </Container>
                    </div>
                </SeparatorAbsSvg2>
            </AnimateScrollIntoViewport>
            <AnimateScrollIntoViewport
                durationIn={0.5}
                durationOut={0.3}
                animateFramer={{ clipPath: POLYGON_RECTANGLE_10_POINTS_RIGHT, }}
                exitFramer={{ clipPath: POLYGON_STAR, }}
                styleToMerge={{ clipPath: POLYGON_STAR, }}
                name="circlesMotionWithWebsitesLR"
            >
                <SeparatorAbsSvg2
                    name="sepNormalRel2FullHorizontalHills"
                    id="circlesMotionWithWebsitesLRMem"
                    optionsSep={{
                        dPathOrderNotIn: [ 0 ],
                        styleSvg: {
                            height: "100px",
                            transform: 'rotateY(180deg)'
                        },
                        svgYPadding: 0,
                        isRelative: true,
                    }} >
                    <div style={{ position: "relative", backgroundColor: sepFillColor, color: sepFillColorContrastText }}                    >
                        <Container maxWidth="lg">
                            {circlesMotionWithWebsitesLRMem}
                        </Container>
                    </div>
                </SeparatorAbsSvg2>
            </AnimateScrollIntoViewport>
            <LazySeparatorMovingImgOnPipe {...optionsSeparatorMovingImgOnPipe} />


            {/* <LazyLeavesInDropAnimation
                styleWrap={{ position: "absolute", top: "80px", left: "0%", scale: 0, }}
                animate={{
                    width: "20%",
                    rotateZ: [ null, null, 90, 90 ],
                    scale: [ 0, 1, 1, 0.5 ],
                    transitionEnd: {
                        // display: "none",
                    },
                    transition: {
                        delay: 2, duration: 10,
                        times: [ 0, 0.8, 0.9, 1 ],
                        type: "spring",
                        damping: 1,
                        stiffness: 10,
                    }
                }}
                name="LazyLeavesInDropAnimation1"
            />
            <LazyLeavesInDropAnimation styleWrap={{ position: "absolute", top: "80px", right: "0%", scale: 0, }}
                animate={{
                    width: "20%",
                    rotateZ: -90,
                    scale: [ 0, 1, 1, 0 ],
                    transitionEnd: {
                        display: "none",
                    },
                    transition: {
                        type: "spring",
                        stiffness: 300, //180
                        damping: 5, // 10,
                        delay: 2, duration: 10,
                        times: [ 0, 0.01, 0.9, 1 ],
                    }
                }}
                name="LazyLeavesInDropAnimation2"
            /> */}
            <Container
                maxWidth="xl"
                className='cont'
                style={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Box component={motion.div}
                    className="fffz"
                    layout
                    animate={{
                        scale: [ 0, 1, 1, 0 ],
                        width: [ null, "50vw", "50vw", "50vw" ],
                        rotateZ: [ null, null, 90, 90 ],
                        transitionEnd: {
                            display: "none",
                        },
                        transition: {
                            delay: 8,
                            duration: 7,
                            times: [ 0, 0.8, 0.9, 1 ],
                            // type: "spring",
                            // damping: 1,
                            // stiffness: 10,
                        }
                    }}
                    style={{
                        ...[ "md", "lg", "xl" ].includes(breakpoint) ? {
                            position: "absolute",
                            top: 0,
                            left: '40%'
                        } : {
                            position: "absolute",
                            top: 0,
                            left: '40%'
                        },
                        alignSelf: 'center',
                        maxWidth: "320px",

                        scale: 0,
                        display: "block"
                    }}
                >
                    <DropEffect styleDrop={{
                        width: "100%"
                    }}
                    >
                    </DropEffect>
                </Box>

            </Container>


        </React.Fragment>
    );
};

export default MainUp;
