import React, { lazy, Suspense } from 'react'
const AtroposEffectTree = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'AtroposEffectTreeLazy' */ './AtroposEffectTree'));
const AtroposEffectTreeLazy = (props) => {
    return (
        <Suspense
        // fallback={<div>Loading AnimateScrollIntoViewport ...</div>}
        >
            <AtroposEffectTree {...props} />
        </Suspense>
    )
}

export default AtroposEffectTreeLazy